import type { PropOfType } from '~/models/Utilities'
import type { TeacherArticle } from '~/models/TeacherArticle'
import type { SubjectCode } from '~/models/Subject'
import type { ProductVariation } from '~/models/Product'
import type { Presentation } from '~/models/Presentation/Presentation'
import type { VideoPage } from '~/models/Presentation/Pages/VideoPage'
import type { ThreeStepPage } from '~/models/Presentation/Pages/ThreeStepPage'
import type { SideBySidePage } from '~/models/Presentation/Pages/SideBySidePage'
import type { RelatedContentPage } from '~/models/Presentation/Pages/RelatedContentPage'
import type { OnlyTextPage } from '~/models/Presentation/Pages/OnlyTextPage'
import type { ImageAndMaybeText } from '~/models/Presentation/Pages/ImageAndMaybeText'
import type { GridPage, TextAndImage } from '~/models/Presentation/Pages/GridPage'
import type { AudioPage } from '~/models/Presentation/Pages/AudioPage'
import type { ArticlePage } from '~/models/Presentation/Pages/ArticlePage'
import type { GradeCode } from '~/models/Grade'
import type { SortField, SortOrder } from '~/models/Content/Sort'
import type { Location, SearchResponse, SearchResult } from '~/models/Content/Response'
import type { ContentVideo } from '~/models/Content/ContentVideo'
import type { ContentSvg } from '~/models/Content/ContentSvg'
import type { ContentSubjectHeader } from '~/models/Content/ContentSubjectHeader'
import type { ContentSubject } from '~/models/Content/ContentSubject'
import type { ContentSectionStandalone } from '~/models/Content/ContentSectionStandalone'
import type { ContentSectionExpanded } from '~/models/Content/ContentSectionExpanded'
import type { ContentSectionBox } from '~/models/Content/ContentSectionBox'
import type { ContentProductPart } from '~/models/Content/ContentProductPart'
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { ContentProductContainer } from '~/models/Content/ContentProductContainer'
import type { ContentProductArticle } from '~/models/Content/ContentProductArticle'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import type { ContentPdfBook } from '~/models/Content/ContentPdfBook'
import type { ContentLottie } from '~/models/Content/ContentLottie'
import type { ContentLink } from '~/models/Content/ContentLink'
import type { ContentLearningPath } from '~/models/Content/ContentLearningPath'
import type { ContentImage } from '~/models/Content/ContentImage'
import type { ContentFolder } from '~/models/Content/ContentFolder'
import type { ContentFlytTask } from '~/models/Content/ContentFlytTask'
import type { ContentFlashcard, ContentFlashcardDeck } from '~/models/Content/ContentFlashcards'
import type { ContentFile } from '~/models/Content/ContentFile'
import type { ContentFeatured } from '~/models/Content/ContentFeatured'
import type { ContentColophon } from '~/models/Content/ContentColophon'
import type { ContentBookReader } from '~/models/Content/ContentBookReader'
import type { ContentBlogPost } from '~/models/Content/ContentBlogPost'
import type { ContentAuthor } from '~/models/Content/ContentAuthor'
import type { ContentAudio } from '~/models/Content/ContentAudio'
import type { ContentArticle } from '~/models/Content/ContentArticle'
import type { BaseItem } from '~/models/Content/BaseItem'
import type {
  BaseField,
  DateField,
  FieldValue,
  FileField,
  ImageRelationField,
  ImageSize,
  LanguageCode,
  MetadataField,
  RelationField,
  RelationsField,
  RichTextField,
  TagField,
  TagsField,
  UrlField,
  ViewMode,
} from '~/models/Content/BaseField'
import stringUtils from '~/utils/stringUtils'
import { rewriteMediaURL } from '~/utils/media'
import { sortByGradeIndex } from '~/utils/gradeSorter'
import { Section } from '~/models/Content/Section'
import { ContentType } from '~/models/Content/ContentType'
import useTime from '~/composables/useTime'
import useText from '~/composables/useText'
import useColorPair from '~/composables/useColorPair'
import { ContentFlashcard, ContentFlashcardDeck } from '~/models/Content/ContentFlashcards'

export default () => {
  const { getColorPair, getColorTheme, getBackgroundColor } = useColorPair()
  const { getTextSizes, getFontFamily, getFontWeight, getTextMaxWidth } = useText()
  const { richTextIsEmpty } = stringUtils()
  const { timestampInFuture } = useTime()

  const getContentTypeIdentifier = (location: Location) => location.ContentInfo.Content.ContentType._identifier

  const getCurrentVersion = (location: Location) => location.ContentInfo.Content.CurrentVersion.Version

  const getFields = (location: Location) => getCurrentVersion(location).Fields.field

  const getField = <T extends FieldValue>(location: Location, identifier: string) => getFields(location)
    .find(({ fieldDefinitionIdentifier }) => fieldDefinitionIdentifier === identifier) as BaseField<T>

  const getFieldValue = <T extends FieldValue>(location: Location, identifier: string, defaultValue?: T): T =>
    getField<T>(location, identifier)?.fieldValue ?? defaultValue

  const getMetadata = (location: Location): MetadataField | undefined => {
    const metadata = getFieldValue<MetadataField>(location, 'metadata')

    if ((metadata == null) || (typeof metadata !== 'object')) return

    return {
      ...metadata,
      elementURI: rewriteMediaURL(metadata.elementURI)
    }
  }

  const getNewState = (location: Location, identifier: string): boolean => {
    const fieldValue = getFieldValue<DateField>(location, identifier)
    return fieldValue ? timestampInFuture(fieldValue.timestamp) : false
  }

  const getTagKeywords = <T extends string>(location: Location, identifier: string): T[] =>
    getFieldValue<TagsField>(location, identifier, [])
      .flatMap((tag: TagField) => Object.values(tag.keywords)) as T[]

  const getTagRemoteIds = <T extends string>(location: Location, identifier: string): T[] =>
    getFieldValue<TagsField>(location, identifier, [])
      .map((tag: TagField) => tag.remote_id) as T[]

  const getEnhancedSelection = <T extends string>(location: Location, identifier: string, defaultValue?: T): T =>
    getFieldValue<T[]>(location, identifier, [])[0] ?? defaultValue

  const getRichText = (location: Location, identifier: string) => {
    const intro = getFieldValue<RichTextField>(location, identifier)
    const value = (intro?.xhtml5output || intro?.xml || '').replace('<?xml version="1.0" encoding="UTF-8"?>\n', '')
    return richTextIsEmpty(value) ? '' : value
  }

  const getThumbnail = (location: Location): { src: string; alt: string } => {
    const src = location.ContentInfo.Content.CurrentVersion.Version.Thumbnail?.resource
    return src && !src.includes('ibexaadminui')
      ? { src, alt: getFieldValue<ImageRelationField>(location, 'thumbnail')?.alternativeText ?? '' }
      : { src: getFieldValue<MetadataField>(location, 'metadata')?.image ?? '', alt: '' }
  }

  const getIdFromHref = (location: Location, prop: PropOfType<Location, ({ _href: string } | undefined)>, index = -1): number | null => {
    const value = (location[prop]?._href || '').split('/').slice(index)[0]
    return value ? Number(value) : null
  }

  const getIdFromContentHref = (location: Location, prop: PropOfType<Location['ContentInfo']['Content'], ({ _href: string } | undefined)>, index = -1): number | null => {
    const value = (location.ContentInfo.Content[prop]?._href || '').split('/').slice(index)[0]
    return value ? Number(value) : null
  }

  const getLanguageCodes = (location: Location) =>
    location.ContentInfo.Content.CurrentVersion.Version.VersionInfo.languageCodes.split(',') as LanguageCode[]

  const mapBaseFields = (location: Location): BaseItem => ({
    title: location.ContentInfo.Content.TranslatedName,
    locationId: location.id,
    pathString: location.pathString,
    contentId: location.ContentInfo.Content._id,
    remoteId: location.ContentInfo.Content._remoteId,
    publishedDate: location.ContentInfo.Content.publishedDate,
    mainLocationId: getIdFromContentHref(location, 'MainLocation') ?? location.id,
    parentLocationId: getIdFromHref(location, 'ParentLocation'),
    contentTypeIdentifier: getContentTypeIdentifier(location),
    sectionId: getIdFromContentHref(location, 'Section') ?? Section.Standard,
    shortTitle: getFieldValue<string>(location, 'short_title', ''),
    grades: getTagKeywords<GradeCode>(location, 'level_tag').sort(sortByGradeIndex),
    intro: getRichText(location, 'intro'),
    label: getEnhancedSelection<string>(location, 'label'),
    thumbnail: getThumbnail(location).src,
    thumbnailAlt: getThumbnail(location).alt,
    languageCodes: getLanguageCodes(location),
    sortField: <SortField>location.sortField?.toLowerCase(),
    sortOrder: <SortOrder>location.sortOrder?.toLowerCase(),
    new: getNewState(location, 'new_until'),
    children: [],
  })

  const mapProductArticle = (location: Location): ContentProductArticle => ({
    ...mapBaseFields(location),
    keywords: getTagKeywords<string>(location, 'keywords'),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
  })

  const mapProductPart = (location: Location): ContentProductPart => ({
    ...mapBaseFields(location),
    keywords: getTagKeywords<string>(location, 'keywords'),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
  })

  const mapVideo = (location: Location): ContentVideo => ({
    ...mapBaseFields(location),
    metadata: getMetadata(location),
    embedCode: getFieldValue<string>(location, 'code'),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
  })

  const mapAudio = (location: Location): ContentAudio => ({
    ...mapBaseFields(location),
    metadata: getMetadata(location),
  })

  const mapProductHeader = (location: Location): ContentProductHeader => ({
    ...mapBaseFields(location),
    viewMode: getEnhancedSelection<ViewMode>(location, 'view_mode'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    backgroundMedia: getFieldValue<RelationField>(location, 'background_media'),
    backgroundColor: getBackgroundColor(getFieldValue<TagsField>(location, 'background_color', [])[0]?.remote_id ?? ''),
    colorPair: getColorTheme(getFieldValue<TagsField>(location, 'color_pair', [])[0]?.remote_id ?? ''),
    teacherGuide: getFieldValue<RelationField>(location, 'teacher_relation'),
  })

  const mapProductPackage = (location: Location): ContentProductPackage => ({
    ...mapBaseFields(location),
    subtitle: getFieldValue<string>(location, 'subtitle'),
    video: getFieldValue<RelationField>(location, 'video_relation'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
  })

  const mapProductContainer = (location: Location): ContentProductContainer => ({
    ...mapBaseFields(location),
    image: getFieldValue<ImageRelationField>(location, 'image'),
  })

  const mapFolder = (location: Location): ContentFolder => ({
    ...mapBaseFields(location),
    image: getFieldValue<RelationField>(location, 'image'),
  })

  const mapFile = (location: Location): ContentFile => ({
    ...mapBaseFields(location),
    file: getFieldValue<FileField>(location, 'file'),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
    fileUrl: getFieldValue<string>(location, 'file_url'),
  })

  const mapBlogPost = (location: Location): ContentBlogPost => ({
    ...mapBaseFields(location),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    lottie: getFieldValue<RelationField>(location, 'lottie_relation'),
    tags: getTagKeywords<string>(location, 'tags'),
    body: getRichText(location, 'body'),
  })

  const mapLink = (location: Location): ContentLink => ({
    ...mapBaseFields(location),
    url: getFieldValue<UrlField>(location, 'link_external')?.link || '',
    urlText: getFieldValue<UrlField>(location, 'link_external')?.text || '',
    image: getFieldValue<ImageRelationField>(location, 'image'),
  })

  const mapLearningPath = (location: Location): ContentLearningPath => ({
    ...mapBaseFields(location),
    keyInformation: getFieldValue<string>(location, 'key_information'),
    keywords: getTagKeywords<string>(location, 'keywords'),
    useMainLocation: getFieldValue<boolean>(location, 'use_main_location') ?? true,
    ignoreVisibility: getFieldValue<boolean>(location, 'ignore_visibility') ?? false,
    video: getFieldValue<RelationField>(location, 'video_relation'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    svg: getFieldValue<RelationField>(location, 'svg_relation'),
    body: getRichText(location, 'body'),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
  })

  const mapProduct = (location: Location): ContentProduct => ({
    ...mapBaseFields(location),
    ean: getFieldValue<string>(location, 'ean', `ean-${location.id}`),
    variation: getEnhancedSelection<ProductVariation>(location, 'product_variation'),
    grades: getTagKeywords<GradeCode>(location, 'levels'),
    subjects: getTagRemoteIds<SubjectCode>(location, 'subjects'),
    description: getRichText(location, 'description'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    learningPathRelation: getFieldValue<RelationsField>(location, 'product_learningpath'),
    flexibleContentRelation: getFieldValue<RelationsField>(location, 'product_flexible_content'),
    teacherContentRelation: getFieldValue<RelationsField>(location, 'product_teacher_content'),
    interdisciplinaryContentRelation: getFieldValue<RelationField>(location, 'interdiciplinary_content'),
    colophonContentRelation: getFieldValue<RelationField>(location, 'about'),
  })

  const mapBookReader = (location: Location): ContentBookReader => ({
    ...mapBaseFields(location),
    description: getRichText(location, 'description'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    authors: getFieldValue<RelationsField>(location, 'authors') ?? { destinationContentIds: [] },
    publishYear: getFieldValue<number>(location, 'publish_year') ?? 0,
    genres: getTagKeywords<string>(location, 'genre'),
    themes: getTagKeywords<string>(location, 'theme'),
    hasAudio: getFieldValue<boolean>(location, 'has_audio') ?? false,
    relatedContent: getFieldValue<RelationsField>(location, 'related_content') ?? { destinationContentIds: [] },
    series: getTagKeywords<string>(location, 'series'),
    pageNumbers: getFieldValue<number>(location, 'page_numbers') ?? 0,
    identifier: getFieldValue<string>(location, 'identifier'),
    languages: getTagKeywords<string>(location, 'language'),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
  })

  const mapAuthor = (location: Location): ContentAuthor => ({
    ...mapBaseFields(location),
    firstname: getFieldValue<string>(location, 'firstname', ''),
    lastname: getFieldValue<string>(location, 'lastname', ''),
    email: getFieldValue<string>(location, 'email'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    description: getRichText(location, 'description'),
  })

  const mapPresentation = (location: Location): Presentation => ({
    ...mapBaseFields(location),
    pages: getFieldValue<RelationsField>(location, 'pages') || { destinationContentIds: [] },
    teacherGuide: getFieldValue<RelationField>(location, 'teacher_relation'),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
  })

  const mapBasePage = (location: Location) => ({
    ...mapBaseFields(location),
    colorPair: getColorPair(getTagKeywords<string>(location, 'color_pair')[0]),
    teacherGuide: getFieldValue<RelationField>(location, 'teacher_relation'),
    subPages: 1,
    step: 1,
  })

  const mapRelatedContentPage = (location: Location): RelatedContentPage => ({
    ...mapBasePage(location),
    heading: getFieldValue<string>(location, 'heading', ''),
    textSizes: getTextSizes(getEnhancedSelection<string>(location, 'font_size')),
    textMaxWidth: getTextMaxWidth(getEnhancedSelection<string>(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection<string>(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection<string>(location, 'font_weight')),
    body: getRichText(location, 'body'),
    relatedContent: getFieldValue<RelationsField>(location, 'related_content'),
  })

  const mapThreeStepPage = (location: Location): ThreeStepPage => ({
    ...mapBasePage(location),
    header: getFieldValue<string>(location, 'header') || '',
    subHeader: getFieldValue<string>(location, 'sub_header') || '',
    image: getFieldValue<ImageRelationField>(location, 'image') || {} as ImageRelationField,
    svg: getFieldValue<RelationField>(location, 'svg') || {} as RelationField,
    lottie: getFieldValue<RelationField>(location, 'lottie') || {} as RelationField,
    body: getRichText(location, 'body'),
    subPages: 3,
    textSizes: getTextSizes(getEnhancedSelection<string>(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection<string>(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection<string>(location, 'font_weight')),
    imageSize: getEnhancedSelection<ImageSize>(location, 'image_size') || 'contain',
  })

  const mapGridPage = (location: Location): GridPage => ({
    ...mapBasePage(location),
    textAndImages: getFieldValue<RelationsField>(location, 'text_and_image') || { destinationContentIds: [] },
    audioEmbedVariant: getTagRemoteIds(location, 'audio_embed_variant')[0] ?? 'default',
  })

  const mapSideBySidePage = (location: Location): SideBySidePage => ({
    ...mapBasePage(location),
    header: getFieldValue<string>(location, 'heading') || '',
    body: getRichText(location, 'body'),
    image: getFieldValue<ImageRelationField>(location, 'image') || {} as ImageRelationField,
    svg: getFieldValue<RelationField>(location, 'svg') || {} as RelationField,
    lottie: getFieldValue<RelationField>(location, 'lottie') || {} as RelationField,
    textSizes: getTextSizes(getEnhancedSelection<string>(location, 'font_size')),
    textMaxWidth: getTextMaxWidth(getEnhancedSelection<string>(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection<string>(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection<string>(location, 'font_weight')),
    imagePlacement: getFieldValue<string[]>(location, 'image_placement')?.[0] || 'right-50',
    imageSize: getEnhancedSelection<ImageSize>(location, 'image_size') || 'cover',
  })

  const mapImageAndMaybeText = (location: Location): ImageAndMaybeText => ({
    ...mapBasePage(location),
    header: getFieldValue<string>(location, 'heading') || '',
    image: getFieldValue<ImageRelationField>(location, 'image') || {} as ImageRelationField,
    svg: getFieldValue<RelationField>(location, 'svg') || {} as RelationField,
    lottie: getFieldValue<RelationField>(location, 'lottie') || {} as RelationField,
    textSizes: getTextSizes(getEnhancedSelection(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection(location, 'font_weight')),
    textPlacement: getFieldValue<string[]>(location, 'text_placement')?.[0] || 'top-left',
    imageSize: getEnhancedSelection<ImageSize>(location, 'image_size') || 'cover',
  })

  const mapOnlyTextPage = (location: Location): OnlyTextPage => ({
    ...mapBasePage(location),
    header: getFieldValue<string>(location, 'heading') || '',
    body: getRichText(location, 'body'),
    textSizes: getTextSizes(getEnhancedSelection<string>(location, 'font_size')),
    textMaxWidth: getTextMaxWidth(getEnhancedSelection<string>(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection<string>(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection<string>(location, 'font_weight')),
  })

  const mapArticlePage = (location: Location): ArticlePage => ({
    ...mapBasePage(location),
    header: getFieldValue<string>(location, 'heading') || '',
    body: getRichText(location, 'body'),
    textSizes: getTextSizes(getEnhancedSelection<string>(location, 'font_size')),
    textMaxWidth: getTextMaxWidth(getEnhancedSelection<string>(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection<string>(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection<string>(location, 'font_weight')),
    image: getFieldValue<RelationField>(location, 'image'),
    imagePlacement: getFieldValue<string[]>(location, 'image_placement')?.[0] || 'right-50',
    imageSize: getEnhancedSelection<ImageSize>(location, 'image_size') || 'cover',
    content: getFieldValue<RelationsField>(location, 'content') || { destinationContentIds: [] },
  })

  const mapAudioPage = (location: Location): AudioPage => ({
    ...mapBasePage(location),
    header: getFieldValue<string>(location, 'heading') || '',
    body: getRichText(location, 'body'),
    audio: getFieldValue<RelationField>(location, 'audio'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    svg: getFieldValue<RelationField>(location, 'svg'),
    lottie: getFieldValue<RelationField>(location, 'lottie'),
    textSizes: getTextSizes(getEnhancedSelection<string>(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection<string>(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection<string>(location, 'font_weight')),
    metadata: getMetadata(location),
  })

  const mapVideoPage = (location: Location): VideoPage => ({
    ...mapBasePage(location),
    header: getFieldValue<string>(location, 'heading', ''),
    body: getRichText(location, 'body'),
    textSizes: getTextSizes(getEnhancedSelection<string>(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection<string>(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection<string>(location, 'font_weight')),
    video: getFieldValue<RelationField>(location, 'video'),
    fontSize: getEnhancedSelection<string>(location, 'font_size') || 'small',
    metadata: getMetadata(location),
  })

  const mapTextAndImage = (location: Location): TextAndImage => ({
    ...mapBaseFields(location),
    header: getFieldValue<string>(location, 'header') || '',
    body: getRichText(location, 'body'),
    image: getFieldValue<ImageRelationField>(location, 'image') || {} as ImageRelationField,
    svg: getFieldValue<RelationField>(location, 'svg') || {} as RelationField,
    lottie: getFieldValue<RelationField>(location, 'lottie') || {} as RelationField,
    textSizes: getTextSizes(getEnhancedSelection<string>(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection<string>(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection<string>(location, 'font_weight')),
    imageSize: getEnhancedSelection<ImageSize>(location, 'image_size') || 'cover',
  })

  const mapFlashcards = (location: Location): ContentFlashcardDeck => ({
    ...mapBaseFields(location),
    cards: getFieldValue<RelationsField>(location, 'cards'),
    activity: getFieldValue<string>(location, 'activity'),
    colorPair: getColorPair(getTagKeywords<string>(location, 'color_pair')[0]),
    teacherGuide: getFieldValue<RelationField>(location, 'teacher_relation'),
    textSizes: getTextSizes(getEnhancedSelection<string>(location, 'font_size')),
    textMaxWidth: getTextMaxWidth(getEnhancedSelection<string>(location, 'font_size')),
    fontFamily: getFontFamily(getEnhancedSelection<string>(location, 'font_family')),
    fontWeight: getFontWeight(getEnhancedSelection<string>(location, 'font_weight')),
  })

  const mapFlashcard = (location: Location): ContentFlashcard => ({
    ...mapBaseFields(location),
    bodyFront: getRichText(location, 'body_front'),
    bodyBack: getRichText(location, 'body_back'),
    imageFront: getFieldValue<RelationField>(location, 'image_front'),
    audioFront: getFieldValue<RelationField>(location, 'audio_front'),
  })

  const mapImage = (location: Location): ContentImage => ({
    ...mapBaseFields(location),
    shortCaption: getFieldValue<string>(location, 'short_caption') || '',
    caption: getRichText(location, 'caption'),
    image: getFieldValue<ImageRelationField>(location, 'image') || {} as ImageRelationField,
    palette: getFieldValue<string>(location, 'palette') || '',
    copyright: getFieldValue<string>(location, 'copyright') || '',
    magnifier: getFieldValue<boolean>(location, 'magnifier') || false,
  })

  const mapSvg = (location: Location): ContentSvg => ({
    ...mapBaseFields(location),
    svg: getFieldValue<RelationField>(location, 'svg_file') || {} as RelationField,
    alternativeText: getFieldValue<string>(location, 'alt_text'),
    caption: getRichText(location, 'caption'),
    copyright: getFieldValue<string>(location, 'copyright') || '',
    magnifier: getFieldValue<boolean>(location, 'magnifier') || false,
  })

  const mapFlytTask = (location: Location): ContentFlytTask => ({
    ...mapBaseFields(location),
    taskId: getFieldValue<string>(location, 'task_id', ''),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
  })

  const mapArticle = (location: Location): ContentArticle => ({
    ...mapBaseFields(location),
    body: getRichText(location, 'body'),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
    difficulty: getTagKeywords<string>(location, 'difficulty')[0],
  })

  const mapLottie = (location: Location): ContentLottie => ({
    ...mapBaseFields(location),
    alternativeText: getFieldValue<string>(location, 'alt_text', ''),
    file: getFieldValue<FileField>(location, 'file'),
  })

  const mapPdfBook = (location: Location): ContentPdfBook => ({
    ...mapBaseFields(location),
    metadata: getMetadata(location),
    activity: getTagKeywords<string>(location, 'activity_tag')[0],
  })

  const mapTeacherArticle = (location: Location): TeacherArticle => ({
    ...mapBaseFields(location),
    contents: getFieldValue<RelationsField>(location, 'contents', { destinationContentIds: [] }),
  })

  const mapColophon = (location: Location): ContentColophon => ({
    ...mapBaseFields(location),
    body: getRichText(location, 'body')
  })

  const mapSectionExpanded = (location: Location): ContentSectionExpanded => ({
    ...mapBaseFields(location),
    grades: getTagKeywords<GradeCode>(location, 'grades').sort(sortByGradeIndex),
    subjects: getTagRemoteIds<SubjectCode>(location, 'subjects'),
    description: getRichText(location, 'description'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    color: getTagRemoteIds<string>(location, 'color'),
    resources: getFieldValue<RelationsField>(location, 'resources'),
    product: getFieldValue<RelationField>(location, 'product'),
  })

  const mapSectionBox = (location: Location): ContentSectionBox => ({
    ...mapBaseFields(location),
    grades: getTagKeywords<GradeCode>(location, 'grades').sort(sortByGradeIndex),
    subjects: getTagRemoteIds<SubjectCode>(location, 'subjects'),
    description: getRichText(location, 'description'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    color: getTagRemoteIds<string>(location, 'color'),
    package: getFieldValue<RelationField>(location, 'package'),
    featured: getFieldValue<RelationsField>(location, 'featured'),
    product: getFieldValue<RelationField>(location, 'product'),
  })

  const mapSectionStandalone = (location: Location): ContentSectionStandalone => ({
    ...mapBaseFields(location),
    grades: getTagKeywords<GradeCode>(location, 'grades').sort(sortByGradeIndex),
    subjects: getTagRemoteIds<SubjectCode>(location, 'subjects'),
    description: getRichText(location, 'description'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    color: getTagRemoteIds<string>(location, 'color'),
    alignRight: getFieldValue<boolean>(location, 'align_right') ?? false,
    resource: getFieldValue<RelationField>(location, 'resource'),
    product: getFieldValue<RelationField>(location, 'product'),
  })

  const mapFeatured = (location: Location): ContentFeatured => ({
    ...mapBaseFields(location),
    lottie: getFieldValue<RelationField>(location, 'lottie'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    description: getRichText(location, 'description'),
    relation: getFieldValue<RelationField>(location, 'relation'),
  })

  const mapSubject = (location: Location): ContentSubject => ({
    ...mapBaseFields(location),
    subjects: getTagRemoteIds(location, 'subjects'),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    description: getRichText(location, 'description'),
  })

  const mapSubjectHeader = (location: Location): ContentSubjectHeader => ({
    ...mapBaseFields(location),
    grades: getTagKeywords<GradeCode>(location, 'grades').sort(sortByGradeIndex),
    image: getFieldValue<ImageRelationField>(location, 'image'),
    description: getRichText(location, 'description'),
  })

  const mapContent = (location: Location) => {
    const identifier = getContentTypeIdentifier(location)
    switch (identifier) {
      case ContentType.ProductArticle:
        return mapProductArticle(location)
      case ContentType.ProductPart:
        return mapProductPart(location)
      case ContentType.Video:
        return mapVideo(location)
      case ContentType.Audio:
        return mapAudio(location)
      case ContentType.File:
        return mapFile(location)
      case ContentType.BlogPost:
        return mapBlogPost(location)
      case ContentType.ProductHeader:
        return mapProductHeader(location)
      case ContentType.ProductPackage:
        return mapProductPackage(location)
      case ContentType.Link:
        return mapLink(location)
      case ContentType.LearningPath:
        return mapLearningPath(location)
      case ContentType.Product:
        return mapProduct(location)
      case ContentType.ProductContainer:
        return mapProductContainer(location)
      case ContentType.Folder:
        return mapFolder(location)
      case ContentType.BookReader:
        return mapBookReader(location)
      case ContentType.Author:
        return mapAuthor(location)
      case ContentType.Presentation:
        return mapPresentation(location)
      case ContentType.TextAndImage:
        return mapTextAndImage(location)
      case ContentType.ThreeStepPage:
        return mapThreeStepPage(location)
      case ContentType.GridPage:
        return mapGridPage(location)
      case ContentType.SideBySidePage:
        return mapSideBySidePage(location)
      case ContentType.OnlyTextPage:
        return mapOnlyTextPage(location)
      case ContentType.ArticlePage:
        return mapArticlePage(location)
      case ContentType.AudioPage:
        return mapAudioPage(location)
      case ContentType.VideoPage:
        return mapVideoPage(location)
      case ContentType.Image:
        return mapImage(location)
      case ContentType.Svg:
        return mapSvg(location)
      case ContentType.FlytTask:
        return mapFlytTask(location)
      case ContentType.ImageAndMaybeText:
        return mapImageAndMaybeText(location)
      case ContentType.RelatedContentPage:
        return mapRelatedContentPage(location)
      case ContentType.Article:
      case ContentType.ArticleContent:
        return mapArticle(location)
      case ContentType.Flashcards:
        return mapFlashcards(location)
      case ContentType.Flashcard:
        return mapFlashcard(location)
      case ContentType.Lottie:
        return mapLottie(location)
      case ContentType.PdfBook:
        return mapPdfBook(location)
      case ContentType.TeacherArticle:
        return mapTeacherArticle(location)
      case ContentType.Colophon:
        return mapColophon(location)
      case ContentType.SectionExpanded:
        return mapSectionExpanded(location)
      case ContentType.SectionBox:
        return mapSectionBox(location)
      case ContentType.SectionStandalone:
        return mapSectionStandalone(location)
      case ContentType.Featured:
        return mapFeatured(location)
      case ContentType.Subject:
        return mapSubject(location)
      case ContentType.SubjectHeader:
        return mapSubjectHeader(location)
      default:
        return mapBaseFields(location)
    }
  }

  const mapContents = (result: SearchResult) => mapLocations(result).map(mapContent)

  const mapLocations = (result?: SearchResult) => (result?.searchHits?.searchHit ?? [])
    .map((hit) => hit.value.Location)

  const mapResult = (response: SearchResponse) => response.View.Result

  return {
    getTagKeywords,
    getRichText,
    getEnhancedSelection,
    getContentTypeIdentifier,
    getFieldValue,
    mapBaseFields,
    mapLocations,
    mapResult,
    mapContents,
    mapContent,
    mapBlogPost,
    mapAuthor,
    mapPresentation,
  }
}
