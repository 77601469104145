import type { TeacherArticle } from '~/models/TeacherArticle'
import type { Product } from '~/models/Product'
import type { GradeCode } from '~/models/Grade'
import type { FiltersQueryParams } from '~/models/Filter'
import type { PlannedItem } from '~/models/Content/PlannedItem'
import type { ContentResource } from '~/models/Content/ContentResource'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import type { ContentPdfBook } from '~/models/Content/ContentPdfBook'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { ContentLink } from '~/models/Content/ContentLink'
import type { ContentFile } from '~/models/Content/ContentFile'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { ImageVariations } from '~/models/Content/BaseField'
import { unref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { sortBySubjectIndex } from '~/utils/subjectSorter'
import { sortByGradeIndex } from '~/utils/gradeSorter'
import useArrayUtils from '~/utils/arrayUtils'
import { i18n } from '~/translations'
import useSubjectsStore from '~/stores/subjects'
import { useSubjectViewStore } from '~/stores/subjectView'
import useProductStore from '~/stores/product'
import { useAuthStore } from '~/stores/auth'
import router from '~/router'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'
import { QueryParamKey } from '~/models/QueryParamKeys'
import { ProductVariation } from '~/models/Product'
import useSlugify from '~/composables/useSlugify'
import { useContentHelper } from '~/composables/useContentHelper'
import useQueryParams from '~/composables/queryParams'

/**
 * Composable for building URLs for resources. Some resources should get a link to the CMS view,
 * some should be sent to a subject view, some should be sent to a package view, and some should be
 * sent to a product view. This composable handles all of that.
 *
 * Some rules to keep in mind:
 * - If we are in package or product view, we must not send to subject page
 * - If we are in subject view, we may send to package or product view
 * - If there is a grade filter in the URL, we must use that grade filter in the URL we build
 *    - If there is no content in the view for the grade from the filter in the URL, the user will
 *      be asked to select a grade that has content
 * - When a URL is linking to a subject page, we must not set a subject filter for the same URL
 *   - This means no URLs like /fag/nor?f={"s": "NOR"}
 * - When we are in a subject view, and have selected to show resources from a different subject,
 *   the generated URLs have to point to the _current_ subject and not the subject for the resource.
 */
export const useUrlBuilder = () => {
  const { slugify } = useSlugify()
  const { get, getUrl } = useQueryParams<FiltersQueryParams>(QueryParamKey.Filters)
  const route = useRoute()
  if (!route) {
    const url = window.location.pathname
    const errorMessage = `urlBuilder composable was used outside of setup context on ${url}`
    throw new Error(errorMessage)
  }

  const { isPackage, isFile, isUploadedFile, isLearningPath, isBookReader, isFlashcards, isPresentation, isPlannedItem, isLink, isProduct, isContentResource, isFlytTask, isVideo, isPdfBook, isPdf, isTeacherArticle } = useContentHelper()
  const { getProductFromResource } = useProductStore()
  const { activeUserGroup, userPreferredLanguage, userRelevantGrades, isTeacher, userPreferredGrade } = storeToRefs(useAuthStore())
  const { currentSubject } = storeToRefs(useSubjectsStore())
  const { isNewSubject } = useSubjectViewStore()

  const inSubjectView = route && route.name === 'subject'

  const canParseURL = (href: string) => {
    try {
      new URL(href)
      return true
    } catch (e) {
      return false
    }
  }

  const buildReturnPathParam = (url: string, returnPath?: string) => {
    const [mainUrl, urlParams] = url.split('?')
    const params = new URLSearchParams(urlParams)
    if (!returnPath) returnPath = window.decodeURIComponent(router.currentRoute.value.fullPath)
    try {
      params.set('r', window.btoa(`app::${returnPath}`))
    } catch (e) {
      console.warn(`Error encoding returnPath ${returnPath}`)
      console.warn(e)
    }
    return [mainUrl, params]
  }

  const buildResourceUrl = (resource: BaseItem | PlannedItem | ContentResource | ContentLink | ContentFile | ContentPackage | ContentProduct, returnPath?: string): string => {
    if (isUploadedFile(resource)) return resource.url
    if (isLink(resource)) return resource.url
    if (isFile(resource)) return buildFileUrl(resource)
    if (isPackage(resource)) return buildPackageUrl(resource)
    if (isProduct(resource)) return buildProductRoute(resource)
    if (isBookReader(resource)) return buildBookReaderUrl(resource)
    if (isFlashcards(resource)) return buildFlashcardsUrl(resource)
    if (isPresentation(resource)) return buildPresentationUrl(resource)
    if (isFlytTask(resource)) return buildFlytTaskUrl(resource)
    if (isVideo(resource)) return buildVideoUrl(resource)
    if (isPdfBook(resource)) return buildPdfBookUrl(resource)
    if (isTeacherArticle(resource)) return buildTeacherArticleUrl(resource)

    const url = (resource as ContentResource | PlannedItem)?.url ?? getDefaultUrl(resource)
    const [mainUrl, params] = buildReturnPathParam(url, returnPath)
    return `${mainUrl}?${params}`
  }

  const buildLearningPathUrl = (resource: BaseItem, overrideLocationId?: number, returnPath?: string): string => {
    if (!overrideLocationId) return buildResourceUrl(resource, returnPath)
    if (!isLearningPath(resource)) return buildResourceUrl(resource, returnPath)

    const url = isPlannedItem(resource) ? resource.url : getDefaultUrl(resource)
    const [, params] = buildReturnPathParam(url, returnPath)
    return `${import.meta.env.VITE_AUNIVERS_SITEURL}/location/${overrideLocationId}/${resource.locationId}?${params}`
  }

  const formWriterRoute = (baseUrl: string, userId: string): string => {
    const url: URL = new URL(baseUrl)
    url.searchParams.set('gruppe', activeUserGroup.value?.groupId || '')
    url.searchParams.set('elev', userId || '')

    return url.href
  }

  const getDefaultUrl = (item: BaseItem) => `${import.meta.env.VITE_AUNIVERS_SITEURL}/location/${item.locationId}`

  const buildPackageUrl = (item: ContentPackage | ContentResource): string => {
    const product = getProductFromResource(item)
    if (!product) return buildPackageRoute(item)

    // sometimes we don't have a proper pathString, so we have to check for that
    if ((!item.pathString || item.pathString.split('/').length <= 3)) {
      return buildPackageRoute(item)
    }

    const filter = buildFilter(item) ?? {}
    const filterSubject = (filter[QueryParamKey.Subject] ?? '').toLowerCase()

    if (filterSubject && !inSubjectView) {
      delete filter[QueryParamKey.Subject]
    }

    if (filterSubject && currentSubject.value && filterSubject === currentSubject.value) {
      delete filter[QueryParamKey.Subject]
    }

    let baseUrl = resolveBaseUrl(product, filterSubject ?? '')
    if (!route) return buildPackageRoute(item)
    const isGenericView = ['generic', 'product', 'package'].includes(route?.name as string)

    if (isGenericView) {
      // GenericView is currently used for both package and product views. In those cases, we can't
      // use subject for the URL and we have to use the current URL with filter query params
      baseUrl = route.path
      delete filter[QueryParamKey.Subject]
    }

    const { [QueryParamKey.Grade]: queryParamGrade } = get()
    if (queryParamGrade) {
      filter[QueryParamKey.Grade] = queryParamGrade
    }

    return getUrl(filter, baseUrl)
  }

  const buildProductRoute = (item: BaseItem) => {
    const { href } = router.resolve({
      name: 'product',
      params: {
        locationId: item.locationId,
        slug: slugify(item.title),
      },
    })
    return href
  }

  const buildPackageRoute = (item: BaseItem) => {
    const { href } = router.resolve({
      name: 'package',
      params: {
        locationId: item.locationId,
        slug: slugify(item.title),
      },
    })
    return href
  }

  const isAddonProduct = (product: Product): boolean => {
    const productMetadata = product.aunivers ?? {}
    const { productVariation } = productMetadata
    return productVariation === ProductVariation.Addon
  }

  const resolveBaseUrl = (product: Product, subject: string): string => {
    if (isAddonProduct(product)) {
      return router.resolve({
        name: 'product',
        params: {
          locationId: product.aunivers.locationId,
          slug: slugify(product.aunivers.name),
        },
      }).fullPath
    }
    if (subject) {
      const { href } = router.resolve({
        name: 'subject',
        params: {
          subject: inSubjectView ? route.params.subject : subject,
        },
      })
      return href
    }
    return ''
  }

  const buildFileUrl = (item: ContentFile | PlannedItem, forceLanguage?: PreferredLanguage): string => {
    if (isPlannedItem(item) || isContentResource(item)) {
      const [, params] = buildReturnPathParam(item.url)
      return `${item.url}?${params}`
    }

    if (item.fileUrl) {
      return item.fileUrl
    }

    let uri = item?.file?.uri || ''
    if (canParseURL(uri)) {
      const parsedURL = new URL(uri)
      uri = parsedURL.pathname
    }

    const language = forceLanguage ?? userPreferredLanguage.value

    if (language === PreferredLanguage.Nynorsk) {
      uri = `/${PreferredLanguage.Nynorsk}${uri}`
    }

    return `${import.meta.env.VITE_AUNIVERS_SITEURL}${uri}`
  }

  const buildPdfBookUrl = (item: ContentPdfBook): string => {
    if (!item.metadata?.elementURI) {
      console.warn(`Missing elementURI in content ${item.contentId}`)
      return '#'
    }

    if (isPdf(item) && isTeacher.value) {
      return router.resolve({
        name: 'pdf',
        params: {
          locationId: item.locationId,
          slug: slugify(item.title),
        },
      }).fullPath
    }

    return item.metadata.elementURI
  }

  const buildTeacherArticleUrl = (item: TeacherArticle): string => {
    return router.resolve({
      name: 'teacher_article',
      params: {
        locationId: item.locationId,
        slug: slugify(item.title),
      },
    }).fullPath
  }

  const buildFilter = (item: BaseItem): Nullable<FiltersQueryParams> => {
    const { pathString } = item
    if (!pathString) return null

    const { truthy, intersect } = useArrayUtils()

    const product = getProductFromResource(item)

    if (!product) return null

    const { subjects } = product
    const [subject] = [...subjects].sort(sortBySubjectIndex)

    const availableGrades = userPreferredGrade.value ? [userPreferredGrade.value] : userRelevantGrades.value

    const [grade] = intersect<GradeCode>(item.grades, availableGrades).sort(sortByGradeIndex)
    const productMetadata = product.aunivers ?? {}
    const { relatedLocations, locationId: productLocationId } = productMetadata

    const root = Object.values(relatedLocations ?? {})
      .find((locationId) => pathString.includes(`/${locationId}/`)) ?? `/${productLocationId}/`

    const headers = pathString.split(root.toString())[1]
      .split('/').map((number: string) => Number(number))
      .filter(truthy)

    /**
     * Because addon products should not use a subject or grade filter, we simply return the headers
     */
    if (isAddonProduct(product)) return {
      ...(headers ? { [QueryParamKey.Headers]: headers } : {}),
    }

    if (isNewSubject(subject, grade) && Array.isArray(headers) && headers.length > 1) {
      const skippedHeader = headers.pop()
      console.warn('[AU2 warn] We skip the deepest header for new subject view. The header we skipped was', skippedHeader)
    }

    return {
      ...(subject ? { [QueryParamKey.Subject]: subject } : {}),
      ...(headers ? { [QueryParamKey.Headers]: headers } : {}),
      ...(grade ? { [QueryParamKey.Grade]: grade } : {}),
    }
  }

  const buildImageUrlByField = (
    contentId: number,
    fieldDefinitionId: string = 'image',
    variation: ImageVariations = 'thumbnail_large',
  ) => {
    const locale = unref(i18n.global.locale)
    const localePath = locale === PreferredLanguage.Bokmal ? '' : `/${locale}`

    return `${import.meta.env.VITE_AUNIVERS_SITEURL}${localePath}/bilde/${contentId}/${fieldDefinitionId}/${variation}`
  }

  const buildSvgUrlByField = (contentId: number) =>
    `${import.meta.env.VITE_AUNIVERS_SITEURL}/image/svg/${contentId}/svg_file/svg-${contentId}.svg`

  const buildBookReaderUrl = (resource: BaseItem) => {
    return router
      .resolve({
        name: 'bookreader_summary',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title),
        },
      })
      .fullPath
  }

  const buildFlashcardsUrl = (resource: BaseItem) => {
    return router
      .resolve({
        name: 'flashcards',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title),
        },
      })
      .fullPath
  }

  const buildPresentationUrl = (resource: BaseItem): string => {
    return router
      .resolve({
        name: 'presentation',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title || ''),
        },
      })
      .
      fullPath
  }

  const buildVideoUrl = (resource: BaseItem): string => {
    return router
      .resolve({
        name: 'video',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title),
        },
      })
      .fullPath
  }

  const buildFlytTaskUrl = (resource: BaseItem): string => {
    return router.resolve({
      name: 'flyt_task',
      params: {
        locationId: resource.locationId,
        slug: slugify(resource.title),
      },
    })
      .fullPath
  }

  return {
    buildFileUrl,
    isAddonProduct,
    resolveBaseUrl,
    inSubjectView,
    buildResourceUrl,
    formWriterRoute,
    buildFilter,
    buildPackageUrl,
    buildLearningPathUrl,
    buildImageUrlByField,
    buildSvgUrlByField,
  }
}
