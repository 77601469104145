import { defineStore } from 'pinia'
import type { Component } from 'vue'
import { markRaw, ref } from 'vue'
import type { Dialog } from '~/models/Dialog'
import { userInteraction } from '~/models/userInteractions'

export const useDialogStore = defineStore('dialog', () => {
  const dialogs = ref<Dialog[]>([])

  /**
   * Show a dialog and return a promise that resolves when a the dialog component is resolved.
   * The resolve and reject functions are passed to the dialog component as props, so the dialog
   * component can resolve or reject the promise based on what the user does or selects in the
   * dialog. This allows us to simply use the dialog as a promise.
   */
  const showDialog = <T>(component: Component, props?: Record<string, unknown>): Promise<T> => {
    return new Promise(function checkIfAllDialogsClosed(resolve, reject) {
      const dialog = {
        component: markRaw(component),
        activeElement: document.activeElement as HTMLElement,
        props: {
        ...props,
        resolve: (resolveWith: T) => {
          removeDialog(dialog)
          resolve(resolveWith)
        },
        reject: (rejectWith: any) => {
          removeDialog(dialog)
          reject(rejectWith)
        },
      } }
      addDialog(dialog)
    })
  }

  const addDialog = (dialog: Dialog) => dialogs.value.push(dialog)

  const removeDialog = (dialog: Dialog) => {
    const index = dialogs.value.indexOf(dialog)
    let removedDialogs: Dialog[] = []
    if (index !== -1) removedDialogs = dialogs.value.splice(index, 1)
    if (removedDialogs.length === 0) return

    // Focus the element that was focused before the dialog was opened. Hacky, but it works.
    setTimeout(() => removedDialogs[0].activeElement?.focus(), 250)
  }

  return {
    dialogs,
    showDialog,
    addDialog,
    removeDialog,
  }
})

export default useDialogStore
